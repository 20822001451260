import React, {useEffect, useState} from "react";
import {collection, doc, getDoc, getDocs, query, where} from "firebase/firestore";
import {db} from "../firebase";
import config from "./constant/detail.json";
import {Button, Card, CardBody, CardHeader, Col, FormInput, Row,} from "shards-react";
import DataTable from "react-data-table-component";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";

import {Doughnut} from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export default function AvatarTables() {
  const [users, setUsers] = useState([]);
  const [userSelections, setUserSelections] = useState({});
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [tableRowsData, setTableRowsData] = useState([]);
  const [tableRowsDataTemp, setTableRowsDataTemp] = useState([]);
  const [serachName, setSearchname] = useState("");
  const [serachEmail, setSearchemail] = useState("");
  const [serachCharacter, setSearchcharacter] = useState("");
  const [serachArmor, setSearcharmor] = useState("");
  const [serachIndustry, setSearchIndustry] = useState("");
  const [armor, setArmor] = useState("");
  const [weapons, setWeapons] = useState("");
  const [sheild, setShield] = useState("");
  const [modal, setModal] = useState("");
  const [industry, setIndustry] = useState("");
  const [inventary, setInventary] = useState("");
  const [configData , setConfigData] = useState([]);
  const [jsonLoad , setJsonLoad] = useState(false);
  // const [baseModel, setBaseModel] = useState({});
  // const [armorData, setArmorData] = useState({});
  // const [inventoryData, setInventoryData] = useState({});
  // const [shieldData, setShieldData] = useState({});
  // const [weaponData, setWeaponData] = useState({});

  const calcPercentage = (value) => {
    if (value) {
      return (value / userData?.length) * 100;
    } else return 0;
  };
  const calcIn = (value) => {
    if (value) {
      return ((value / userData?.length) * 100)/4;
    } else return 0;
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        // fontColor: "#fff",
        display: true,
        position: "right",
        labels: {
          // color: "white",
          anchor: "center",
        },
      },
    },
  };
  const baseModels = {
    labels:  jsonLoad && configData.avatarScreen.avatar.map((_avatar) => _avatar.name),
    datasets: [
      {
        data: userSelections.avatars && jsonLoad
          ? configData.avatarScreen.avatar.map((_avatar) =>
              calcPercentage(
                userSelections.avatars[_avatar.name.trim()] ?? 0
              ).toFixed(1)
            )
          : [0],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(87,192,75,0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgb(75,192,77)",
        ],
        // datalabels: {
        //   color: "white",
        // },
      },
    ],
  };
  const armors = {
    labels: jsonLoad && configData.armorScreen.armor.map(
      (_armor) => `${_armor.name.trim()} - ${_armor.desc}`
    ),
    datasets: [
      {
        data: userSelections.armors && jsonLoad
          ? configData.armorScreen.armor.map((_armor) =>
              calcPercentage(
                userSelections.armors[_armor.name.trim()] ?? 0
              ).toFixed(1)
            )
          : [0],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(87,192,75,0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgb(75,192,77)",
        ],
        // datalabels: {
        //   color: "white",
        // },
      },
    ],
  };
  const shield = {
    labels: jsonLoad && configData.shieldScreen.sheild.map(
      (_shield) => `${_shield.name.trim()} - ${_shield.desc}`
    ),
    datasets: [
      {
        data: userSelections.shields && jsonLoad
          ? configData.shieldScreen.sheild.map((_shield) =>
              calcPercentage(
                userSelections.shields[_shield.name.trim()] ?? 0
              ).toFixed(1)
            )
          : [0],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(87,192,75,0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgb(75,192,77)",
        ],
        borderWidth: 1.5,
        // datalabels: {
        //   color: "white",
        // },
      },
    ],
  };
  const weapon = {
    labels: jsonLoad && configData.weaponScreen.weapon.map(
      (_weapon) => `${_weapon.name.trim()} - ${_weapon.desc}`
    ),
    datasets: [
      {
        data: userSelections.weapons && jsonLoad
          ? configData.weaponScreen.weapon.map((_weapon) =>
              calcPercentage(
                userSelections.weapons[_weapon.name.trim()] ?? 0
              ).toFixed(1)
            )
          : [0],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(87,192,75,0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgb(75,192,77)",
        ],
        borderWidth: 1.5,
        // datalabels: {
        //   color: "white",
        // },
      },
    ],
  };
  const inventory = {
    labels: config.Inventory.map((_inventory) => _inventory.name.trim()),
    datasets: [
      {
        data: userSelections.inventory
          ? config.Inventory.map((_inventory) =>
          calcIn(
                userSelections.inventory[_inventory.name.trim()] ?? 0
              ).toFixed(1)
            )
          : [0],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(1, 206, 86, 0.2)",
          "rgba(87,192,75,0.2)",
          "rgba(99,118,222,0.2)",
          "rgba(56,219,177,0.2)",
          "rgba(89,255,175,0.2)",
          "rgba(24,112,236,0.2)",
          "rgba(23,217,65,0.2)",
          "rgba(159,217,23,0.2)",
          "rgba(212,41,18,0.2)",
          "rgba(238, 130, 238,0.2)",
          "rgba(255, 165, 0,1)",
          "rgba(255, 165, 0)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(87,192,75,0.2)",
          "rgba(99,118,222,0.2)",
          "rgba(60,219,177,0.2)",
          "rgba(0,255,175,0.63)",
          "rgba(24,112,236,0.72)",
          "rgba(23,217,65,0.64)",
          "rgba(159,217,23,0.71)",
          "rgba(212,41,18,0.63)",
        ],
        // datalabels: {
        //   color: "white",
        // },
      },
    ],
  };

  useEffect(() => {
    loadUsers().then((result) => {
      console.log("Then", result);
      setUsers(result);
      setUserData(result);
      setLoader(false);
      console.log(userData);
      console.log(users.length, "length");
    });
    loadDetailsJson().then(result => {
      console.log(result);
      setConfigData(result);
      console.log(result.avatarScreen.avatar);
      setJsonLoad(true);
    });
  }, []);

  useEffect(() => {
    tempFunc();
  }, [users]);

  const loadDetailsJson = async () => {
    const eventDocRef = doc(db, "events", localStorage.getItem("eventID"));
    const eventDocSnap = await getDoc(eventDocRef);
    if (eventDocSnap.exists()) {
      console.log("Document data:", eventDocSnap.data());
      let obj = eventDocSnap.data();
      const detailId = obj.detailsId;
      console.log(detailId);
      const detailDocRef = doc(db, "details", detailId);
      const detailDocSnap = await getDoc(detailDocRef);
      return detailDocSnap.data();
    } else {
      console.log("No such document!");
    }
  }
  const loadUsers = async () => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("eventID", "==", localStorage.getItem("eventID")), where("attributes.armor", "!=", ""));
    const usersData = await getDocs(q);
    const w = usersData.docs.map((doc) => ({ ...doc.data() }));
    console.log(w);
    console.log("setting users");
    return w;
  };

  const tempFunc = () => {
    console.log(users);

    const _userSelections = {
      avatars: {},
      shields: {},
      weapons: {},
      armors: {},
      inventory: {},
    };

    users.forEach((_user) => {
      _userSelections.avatars[
        _user.attributes.baseCharacter.trim()
      ] = _userSelections.avatars[_user.attributes.baseCharacter.trim()]
        ? _userSelections.avatars[_user.attributes.baseCharacter.trim()] + 1
        : 1;
      _userSelections.shields[_user.attributes.shield.trim()] = _userSelections
        .shields[_user.attributes.shield.trim()]
        ? _userSelections.shields[_user.attributes.shield.trim()] + 1
        : 1;
      _userSelections.weapons[_user.attributes.weapon.trim()] = _userSelections
        .weapons[_user.attributes.weapon.trim()]
        ? _userSelections.weapons[_user.attributes.weapon.trim()] + 1
        : 1;
      _userSelections.armors[_user.attributes.armor.trim()] = _userSelections
        .armors[_user.attributes.armor.trim()]
        ? _userSelections.armors[_user.attributes.armor.trim()] + 1
        : 1;

      for (const _key in _user.attributes.inventory) {
        const _inventory = _user.attributes.inventory[_key];
        _userSelections.inventory[_inventory.trim()] = _userSelections
          .inventory[_inventory.trim()]
          ? _userSelections.inventory[_inventory.trim()] + 1
          : 1;
      }
    });

    console.log(_userSelections);

    setUserSelections(_userSelections);
  };

  useEffect(() => {
    if (armor || sheild || weapons || industry || modal || inventary) {
      let data = userData;
      console.log("test", data);
      console.log("zubair");
      if (armor) {
        if (armor === "Select All") {
          setArmor("");
        } else {
          data = data.filter((item) => {
            if (
              item.attributes.armor
                .toString()
                .toLowerCase()
                .includes(armor.toLowerCase())
            ) {
              return item;
            }
          });
        }
      }
      if (industry) {
        if (industry === "Select All") {
          setIndustry("");
        } else {
          data = data.filter((item) => {
            if (
              item.industry
                .toString()
                .toLowerCase()
                .includes(industry.toLowerCase())
            ) {
              return item;
            }
          });
        }
      }
      if (sheild) {
        if (sheild === "Select All") {
          setShield("");
        } else {
          data = data.filter((item) => {
            if (
              item.attributes.shield
                .toString()
                .toLowerCase()
                .includes(sheild.toLowerCase())
            ) {
              return item;
            }
          });
        }
      }
      if (weapons) {
        if (weapons === "Select All") {
          setWeapons("");
        } else {
          data = data.filter((item) => {
            if (item.attributes.weapon.toString().includes(weapons)) {
              return item;
            }
          });
        }
      }
      if (modal) {
        if (modal === "Select All") {
          setModal("");
        } else {
          data = data.filter((item) => {
            if (item.attributes.baseCharacter.toString().includes(modal)) {
              return item;
            }
          });
        }
      }

      if (inventary) {
        if (inventary === "Select All") {
          setInventary("");
        } else {
          data = data.filter((item) => {
            if (
              item.attributes.inventory[0] === inventary ||
              item.attributes.inventory[1] === inventary ||
              item.attributes.inventory[2] === inventary ||
              item.attributes.inventory[3] === inventary
            ) {
              return item;
            }
          });
        }
      }

      // setUserData(data);
      setUsers(data);
    } else {
      setUsers(userData);
    }
  }, [inventary, armor, weapons, sheild, modal, industry]);

  function clear() {
    console.log("HELLO");
    console.log(tableRowsDataTemp);
    setSearcharmor("");
    setSearchcharacter("");
    setSearchname("");
    setSearchemail("");
    setSearchIndustry("");
    // setUsers(tableRowsDataTemp);
    // setTableRowsData(tableRowsDataTemp);

    console.log(tableRowsData);
  }

  const columns = [
    {
      name: (
        <div>
          <b>Name</b>
        </div>
      ),
      // selector: (row) => row.name + " " + row.email,
      selector: (row) => row.name,
      // right: true,
      sortable: true,
    },

    {
      name: <b>Email</b>,
      selector: (row) => row.email,
      sortable: true,
      // right: true,
    }, ///from here

    {
      name: <b>Base Model</b>,
      selector: (row) => row.attributes["baseCharacter"],
      sortable: true,
      // right: true,
    },

    {
      name: <b>Armor</b>,
      selector: (row) => row.attributes["armor"],
      sortable: true,
      // right: true,
    },
    // {
    //   name: <b width="50%">Inventory</b>,
    //   selector: (row) =>
    //     row["attributes"]["inventory"][0] +
    //     "," +
    //     row["attributes"]["inventory"][1] +
    //     "," +
    //     row["attributes"]["inventory"][2] +
    //     "," +
    //     row["attributes"]["inventory"][3],
    //   sortable: true,
    //   // right: true,
    // },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "rgb(246, 249, 252)",
      },
    },
  };
  useEffect(() => {
    if (
      serachName ||
      serachEmail ||
      serachCharacter ||
      serachArmor ||
      serachIndustry
    ) {
      let data = userData;
      console.log("test", data);
      console.log("zubair");
      if (serachName) {
        data = data.filter((item) => {
          if (
            item.name
              .toString()
              .toLowerCase()
              .includes(serachName.toLowerCase())
          ) {
            return item;
          }
        });
        console.log(serachName, data);
      }
      if (serachIndustry) {
        data = data.filter((item) => {
          if (
            item.industry
              .toString()
              .toLowerCase()
              .includes(serachIndustry.toLowerCase())
          ) {
            return item;
          }
        });
        console.log(serachName, data);
      }
      if (serachEmail) {
        data = data.filter((item) => {
          if (
            item.email
              .toString()
              .toLowerCase()
              .includes(serachEmail.toLowerCase())
          ) {
            return item;
          }
        });
      }
      if (serachCharacter) {
        data = data.filter((item) => {
          if (
            item.attributes.baseCharacter
              .toString()
              .toLowerCase()
              .includes(serachCharacter.toLowerCase())
          ) {
            return item;
          }
        });
      }
      if (serachArmor) {
        data = data.filter((item) => {
          if (
            item.attributes.armor
              .toString()
              .toLowerCase()
              .includes(serachArmor.toLowerCase())
          ) {
            return item;
          }
        });
      }
      setTableRowsData(data);
      setUsers(data);
      console.log(
        serachName,
        serachEmail,
        serachCharacter,
        serachArmor,
        serachIndustry
      );
    } else {
      setUsers(userData);
    }
  }, [serachName, serachEmail, serachCharacter, serachArmor, serachIndustry]);

  return (
    <>
      <div>
        <Card
          style={{ marginLeft: "45px", marginRight: "45px", marginTop: "20px" }}
        >
          <CardHeader>
            <div>
              <h4>Filters</h4>
            </div>
            <Row>
              <Col>
                <h6>Name</h6>
                <FormInput
                  type="text"
                  onChange={(e) => {
                    setSearchname(e.target.value);
                  }}
                  value={serachName}
                  placeholder="Search User By Name"
                />
              </Col>
              <Col>
                <div>
                  <h6>Email</h6>
                  <FormInput
                    type="text"
                    onChange={(e) => {
                      setSearchemail(e.target.value);
                    }}
                    value={serachEmail}
                    placeholder="Search User By Email"
                  />
                </div>
              </Col>
              <Col>
                <div>
                  <h6>Character</h6>
                  <FormInput
                    type="text"
                    value={serachCharacter}
                    onChange={(e) => {
                      setSearchcharacter(e.target.value);
                    }}
                    placeholder="Search User By Character"
                  />
                </div>
              </Col>
              <Col>
                <div>
                  <h6>Armor</h6>
                  <FormInput
                    type="text"
                    value={serachArmor}
                    onChange={(e) => {
                      setSearcharmor(e.target.value);
                    }}
                    placeholder="Search User By Armor"
                  />
                </div>
              </Col>
              {/*<Col>*/}
              {/*  <div>*/}
              {/*    <h6>Industry</h6>*/}

              {/*    <select*/}
              {/*      onChange={(e) => {*/}
              {/*        setSearchIndustry(e.target.value);*/}
              {/*      }}*/}
              {/*      value={serachIndustry}*/}
              {/*      style={{*/}
              {/*        maxWidth: "200px",*/}
              {/*//         padding: "6px 12px",*/}
              {/*//         height: "34px",*/}
              {/*//         border: "1px solid lightgrey",*/}
              {/*//         flex: "1 1 auto",*/}
              {/*        borderRadius: "3px",*/}
              {/*      }}*/}
              {/*      type="select"*/}
              {/*    >*/}
              {/*      <option>Select</option>*/}
              {/*      <option>Finance</option>*/}
              {/*      <option>Travel & Hospitality</option>*/}
              {/*      <option>Retail & E-commerce</option>*/}
              {/*      <option>Technology</option>*/}
              {/*      <option>Media & Entertainment</option>*/}
              {/*//       <option>Manufacturing, Transportation & Logistics</option>*/}
              {/*//       <option>Energy, Oil and Gas</option>*/}
              {/*//       <option>Communication Services</option>*/}
              {/*      <option>*/}
              {/*        Government & Utilities & Social Institutions*/}
              {/*      </option>*/}
              {/*      <option>Professional & Educational Services</option>*/}
              {/*      <option>Human Resources and Recruitment</option>*/}
              {/*      <option>Healthcare & Fitness</option>*/}
              {/*      <option>Marketing & Advertising Services</option>*/}
              {/*      <option>Telecommunications</option>*/}
              {/*    </select>*/}
              {/*  </div>*/}
              {/*</Col>*/}
            </Row>

            <div className="d-flex justify-content-end mt-3">
              <Button onClick={clear}>Clear All</Button>
            </div>
          </CardHeader>
        </Card>
      </div>
      <div>
        <Card
          style={{ marginLeft: "45px", marginRight: "45px", marginTop: "20px" }}
        >
          <CardHeader>
            <div>
              <h4>Reports</h4>
            </div>
            <Row>
              <Col md={8}  >
                <h6>Avatar</h6>
                <div style={{ height: "300px" }}>
                  <Doughnut data={baseModels} options={options} type="" />
                </div>
              </Col>
              <Col md={8} className={"mb-5"}>
                <h6 className="mb-0">Weapons</h6>
                <div style={{ height: "300px" }}>
                  <p className="text-mute">{config.WeaponDetail.des}</p>
                  <Doughnut data={weapon} options={options} type="" />
                </div>
              </Col>
              <Col md={8}>
                <h6 className="mb-0">Shield</h6>
                <p className="text-mute">{config.ShieldDetail.des}</p>
                <div style={{ height: "300px" }}>
                  <Doughnut data={shield} options={options} type="" />
                </div>
              </Col>
              <Col md={8} >
                <h6 className="mb-0">Armor</h6>
                <p className="text-mute">{config.ArmorDetail.des}</p>
                <div style={{ height: "300px" }}>
                  <Doughnut data={armors} options={options} type="" />
                </div>
              </Col>
              {/*<Col md={8}>*/}
              {/*  <h6 className="mb-0">Inventory</h6>*/}
              {/*  <p className="text-mute">{config.InventoryDetail.des}</p>*/}
              {/*  <div style={{ height: "300px" }}>*/}
              {/*    <Doughnut data={inventory} options={options} type="" />*/}
              {/*  </div>*/}
              {/*</Col>*/}
            </Row>
          </CardHeader>
        </Card>
      </div>
      <div style={{ marginLeft: "45px", marginRight: "45px" }}>
        {/* Page Header */}

        {/* Default Light Table */}
        <Row>
          <Col>
            <Card small className="mb-4" style={{ marginTop: "5%" }}>
              <div>
                <h4
                  style={{
                    fontWeight: "500",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    marginLeft: "30px",
                  }}
                >
                  Avatar Details
                </h4>
              </div>
              <CardBody className="p-0 pb-3">
                <DataTable
                  columns={columns}
                  data={users}
                  pagination
                  customStyles={customStyles}
                  progressPending={loader}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
