import React from "react";
import {Link} from "react-router-dom";
import {getAuth, signOut} from "firebase/auth";
// import {auth}
import {Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink,} from "shards-react";

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }
  signOut() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log("sssssssssssssss");
      })
      .catch((error) => {
        // An error happened.
      });
    console.log("  signing out is in progress.....");
  }
  toggleUserActions() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions} className="mt-2">
        <DropdownToggle caret tag={NavLink} style={{cursor:"pointer"}}>
          {/* <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../images/avatars/0.jpg")}
            alt="User Avatar"
          /> */}

          <img
            className="user-avatar rounded-circle mr-2 mb-6 "
            src={require("./../../../../images/colorsx/images.png")}
            alt="User Avatar"
          />

          {/* <i class="material-icons">person</i> */}
          {/* <i class="bi bi-person-circle"></i>
           */}
          {/* <i class="fas fa-user"></i>
          <i/> */}
          <span className="d-none d-md-inline-block" style={{marginLeft:"10px"}}>Admin-{localStorage.getItem("eventID")}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          {/* <DropdownItem tag={Link} to="user-profile">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem tag={Link} to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transactions
          </DropdownItem> */}
          <DropdownItem divider />
          <DropdownItem
              tag={Link}
              to="/"
              onClick={this.signOut}
              className="text-danger"
          >
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
