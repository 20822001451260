import React, {useEffect, useState} from 'react';
import {
    getDoc,
    doc,
    updateDoc,
    collection,
    setDoc,
    serverTimestamp,
    getDocs, query, where,orderBy
} from "firebase/firestore";
import {db, storage, set} from "../firebase";
import { CSVLink, CSVDownload } from "react-csv";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Progress
} from "shards-react";
import DataTable from "react-data-table-component";

const JuryReport = () => {
    const [enableVoting, setEnableVoting] = useState(null);
    const [ideas, setIdeas] = useState([]);
    const [loader, setLoader] = useState(true);

    const columns = [
        {
            name: (
                <div>
                    <b>Project Name</b>
                </div>
            ),
            selector: (row) => (row.description+" " + row.name),
            minWidth: "40%",
            wrap: true
        },
        {
            name: <b>Main Jury</b>,
            selector: (row) => row.voteCountSeniorJury,
        },
        {
            name: <b>Other Jury</b>,
            selector: (row) => row.voteCountJuniorJury,
        },
        {
            name: <b>Weighted Main</b>,
            selector: (row) => (row.voteCountSeniorJury * 0.8).toFixed(2),
        },
        {
            name: <b>Weighted Other</b>,
            selector: (row) => (row.voteCountJuniorJury * 0.2).toFixed(2),
        },
        {
            name: <b>Final Vote Count</b>,
            selector: (row) => ((row.voteCountSeniorJury * 0.8) + (row.voteCountJuniorJury * 0.2)).toFixed(2),
        }
    ];
    useEffect(() => {
        loadVotingDocument().then(result =>
            setEnableVoting(result)
        );
        loadIdeasDocument().then(result => {

            const isFound = result.some(element => {
                if (element.voteCountJuniorJury*0.2+element.voteCountSeniorJury*0.8 > 0) {
                    return true;
                }
                else return false;
            });


            if(isFound){
                setIdeas(result.sort((a,b) => ((b.voteCountSeniorJury * 0.8) + (b.voteCountJuniorJury * 0.2)).toFixed(2) - ((a.voteCountSeniorJury * 0.8) + (a.voteCountJuniorJury * 0.2)).toFixed(2)));
                setLoader(false);
            }else{
                setIdeas(result);
                setLoader(false);
            }
            }
        );
    }, [])
    const loadVotingDocument = async () => {
        const docRef = doc(db, "events", localStorage.getItem("eventID"));
        const docSnap = await getDoc(docRef);
        console.log(docSnap.data());
        return docSnap.data().votingFlag;
    }
    const loadIdeasDocument = async () => {
        const docRef = collection(db, "ideas");
        const q = query(
            docRef,
            where("eventID", "==", localStorage.getItem("eventID"))
            , orderBy("ideaCounter")
        );
        const ideasData = await getDocs(q);
        const w = ideasData.docs.map((doc) => ({...doc.data()}));
        console.log(w);
        return w;
    }
    const toggleVoting = async () => {
        const docRef = doc(db, "events", localStorage.getItem("eventID"));
        await updateDoc(docRef, {votingFlag: !enableVoting});
        setEnableVoting(!enableVoting);
    };
    return (
        <div>
            <div style={{marginRight: "45px", marginLeft: "45px", marginTop: "5%"}}>
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom d-flex justify-content-between">
                                <h4 className="m-0">Execution Projects Voting Report</h4>
                                <button className={enableVoting === null ? "btn btn-primary" : enableVoting === true ? "btn btn-danger " : "btn btn-success"}
                                        onClick={toggleVoting}>{enableVoting === null ?  <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>:enableVoting === true ? "Stop Voting" : "Start Voting"} </button>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                <Container>
                                    {ideas.map(idea => (
                                        <div className="p-2 px-5">
                                            <div> {idea.description+"   "+idea.name}</div>

                                            <Progress value={((idea.voteCountSeniorJury * 0.8) + (idea.voteCountJuniorJury * 0.2)).toFixed(2)} striped={true} max={175}
                                                      className="bg-secondary">{((idea.voteCountSeniorJury * 0.8) + (idea.voteCountJuniorJury * 0.2)).toFixed(2)}</Progress>
                                        </div>
                                    ))}
                                </Container>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom d-flex justify-content-between">
                                <h4 className="m-0">Execution Projects Voting Calculation Report</h4>
                                {/*<button className="btn btn-primary"><CSVLink data={ideas} className="text-white">Download CSV</CSVLink></button>*/}
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                <DataTable
                                    columns={columns}
                                    data={ideas}
                                    pagination
                                    progressPending={loader}
                                    paginationPerPage={50}
                                    paginationRowsPerPageOptions={[50 , 100 , 150 , 200]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default JuryReport;