import React, {useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    FormInput,
    FormRadio,
    Row,
    FormSelect
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import {addDoc, collection, serverTimestamp, setDoc, doc} from "firebase/firestore";
import {useHistory} from "react-router-dom";
import {db} from "../firebase";
import {NotificationManager} from "react-notifications";

const AddUser = () => {
    const [loading, setLoading] = useState(null);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [country, setCountry] = useState("");
    const [juryType, setJuryType] = useState(4);
    const history=useHistory();


    const addUser = async (e) => {
        e.preventDefault();
        try {
            setLoading("pending");
            await setDoc(doc(db, "users",email+"_"+localStorage.getItem("eventID")), {
                name:name,
                country:country,
                email:email,
                voteRate:Number(juryType),
                position:position,
                attributes:{
                    armor:"",
                    baseCharacter:"",
                    shield:"",
                    weapon:"",
                },
                eventID:localStorage.getItem("eventID"),
                timestamp:serverTimestamp(),
                voteSubmit:false,
            });

            setLoading(null);
            NotificationManager.success("User Added Successfully");
            history.push("/usersInfo")
        } catch (e) {
            console.log(e);
            NotificationManager.error("User Not Added");
        }

    };

    return (
        <div >
            <Container className="px-4 pb-4 mt-5">
                <Card>
                    <CardHeader>Add User</CardHeader>
                    <CardBody>
                        <Form onSubmit={addUser}>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label htmlFor="#question">Name</label>
                                        <FormInput
                                            size="lg"
                                            maxlength="200"
                                            disabled={loading === "pending"}
                                            type="text"
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value);
                                            }}
                                            id="name"
                                            placeholder="Name"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <label htmlFor="#question">Email</label>
                                        <FormInput
                                            size="lg"
                                            maxlength="200"
                                            disabled={loading === "pending"}
                                            type="text"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            id="email"
                                            placeholder="Email"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label htmlFor="#question">Country</label>
                                        <FormInput
                                            size="lg"
                                            maxlength="200"
                                            disabled={loading === "pending"}
                                            type="text"
                                            value={country}
                                            onChange={(e) => {
                                                setCountry(e.target.value);
                                            }}
                                            id="country"
                                            placeholder="Country"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <label htmlFor="#question">Position</label>
                                        <FormInput
                                            size="lg"
                                            maxlength="200"
                                            disabled={loading === "pending"}
                                            type="text"
                                            value={position}
                                            onChange={(e) => {
                                                setPosition(e.target.value);
                                            }}
                                            id="position"
                                            placeholder="Position"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <FormGroup>
                                        <label htmlFor="jurytype">Jury Type</label>
                                        <FormSelect value={juryType} onChange={(e) => {
                                            setJuryType(e.target.value);
                                        }}>
                                            <option value={4}>Main Jury</option>
                                            <option value={1}>Other Jury</option>
                                        </FormSelect>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="d-flex justify-content-end">
                                <Button
                                    className=""
                                    disabled={loading === "pending"}
                                    type="submit"
                                >
                                    {loading === "pending" ? (
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    ) : (
                                        "Add"
                                    )}
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Container>

        </div>
    );
};

export default AddUser;