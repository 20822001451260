import React, {useEffect, useState} from 'react';
import {
    getDoc,
    doc,
    updateDoc,
    collection,
    setDoc,
    serverTimestamp,
    getDocs, query, where,orderBy
} from "firebase/firestore";
import {db, storage, set} from "../firebase";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Progress
} from "shards-react";
import DataTable from "react-data-table-component";
import { CSVLink, CSVDownload } from "react-csv";

const JuryAll = () => {
    const [enableVoting, setEnableVoting] = useState(null);
    const [ideas, setIdeas] = useState([]);
    const [loader, setLoader] = useState(true);
    const [csvDownload , setCsvDownload] = useState([]);

    const columns = [
        {
            name: (
                <div>
                    <b>Email</b>
                </div>
            ),
            selector: (row) => row.userId,
        },
        {
            name: <b>Sr.Jury/Jn.Jury</b>,
            selector: (row) => row.voteRate===1?"Jn.Jury":"Sr.Jury",
        },
        {
            name: <b>Country</b>,
            selector: (row) => row.userCountry,
        },
        {
            name: <b>1</b>,
            selector: (row) => row.ideas["1"],
        },

        {
            name: <b>2</b>,
            selector: (row) =>row.ideas["2"] ,
        },
        {
            name: <b>3</b>,
            selector: (row) =>row.ideas["3"],
        }
    ];
    useEffect(() => {
        loadIdeasDocument().then(result => {
            // console.log(result);
                    setIdeas(result);
                    setLoader(false);
            }
        );
    }, [])

    const loadIdeasDocument = async () => {
        const docRef = collection(db, "votingLogs");
        const q = query(
            docRef,
            where("eventID", "==", localStorage.getItem("eventID"))

        );
        const ideasData = await getDocs(q);
        const w = ideasData.docs.map((doc) => ({...doc.data()}));
        console.log(w);
        const _csvDownload = w.map(item => {
            return {
                Email : item.userId,
                Country : item.userCountry,
                juryType : item.voteRate === 4 ? "Main Jury":"Other Jury",
                idea1: item.ideas[1],
                idea2: item.ideas[2],
                idea3: item.ideas[3],
            }
        });
        console.log(_csvDownload);
        setCsvDownload(_csvDownload);
        return w;
    }
    const exportCSV = () =>{

    }

    return (
        <div>
            <div style={{marginRight: "45px", marginLeft: "45px", marginTop: "5%"}}>

                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom d-flex justify-content-between">
                                <h4 className="m-0">Jury Voting Report</h4>
                                <button className="btn btn-primary" ><CSVLink data={csvDownload} className="text-white">Export CSV</CSVLink></button>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                <DataTable
                                    columns={columns}
                                    data={ideas}
                                    pagination
                                    progressPending={loader}
                                    paginationPerPage={100}
                                    paginationRowsPerPageOptions={[100 , 150 , 200 , 250]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default JuryAll;