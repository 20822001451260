import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import BlogPosts from "./views/BlogPosts";
import AddQuestions from "./views/AddQuestions";
import EditQuestions from "./views/EditQuestions";
import ActivateQuestion from "./views/ActivateQuestion";
import ViewQuestions from "./views/ViewQuestions";
import Report from "./views/Report";
import Report2 from "./views/Report2"
import Login from "./views/Login"
import DragableQuestions from "./views/DragableQuestions";
import UserDetail from "./views/UserDetail";
import AvatarTables from "./views/AvatarTables";
import JuryReport from "./views/juryReport";
import JuryAll from "./views/juryAll";
import AddUser from "./views/AddUser";

const juryFlag = localStorage.getItem("isJury");
if(juryFlag === "true"){}
export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/login-user" />
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/usersInfo",
    layout: DefaultLayout,
    component: UserDetail
  },
  {
    path: "/login-user",
    layout: Login,
    component: Login
  },
  {
    path: "/dragablequestions",
    layout: DefaultLayout,
    component: DragableQuestions
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/editQuestions/:id",
    layout: DefaultLayout,
    component: EditQuestions
  },
  {
    path: "/AddQuestions",
    layout: DefaultLayout,
    component: AddQuestions
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/avatarInfo",
    layout: DefaultLayout,
    component: AvatarTables
  },
  {
    path:"/viewreport/:eid/:id",
    layout:DefaultLayout,
    component:Report,
  },
  {
    path:"/copyQuestions",
    layout:DefaultLayout,
    component:Report2,
  },
  {
    path:"/juryReport",
    layout:DefaultLayout,
    component:JuryReport,
  },
  {
    path:"/juryrecords",
    layout:DefaultLayout,
    component:JuryAll,
  },
  {
    path:"/addUser",
    layout:DefaultLayout,
    component:AddUser,
  },
];
