export default function() {
  const juryFlag = localStorage.getItem("isJury");
  console.log(juryFlag);
  if( localStorage.getItem("isJury") === "false"){
    return[
      {
        title: "User Avatar Details",
        htmlBefore: '<i  class="fas fa-info-circle  text-success "  > </i>',
        to: "/avatarInfo",

      },
      {
        title: "Users",
        htmlBefore: '<i class="fas fa-users text-dark"></i>',
        to: "/usersInfo",
      },
      {
        title: "Questions",
        htmlBefore: '<i class="fas fa-question-circle text-secondary"></i>',
        to: `/dragablequestions`,
      }
    ];
  }else {
    return [
      {
        title: "User Avatar Details",
        htmlBefore: '<i  class="fas fa-info-circle  text-success "  > </i>',
        to: "/avatarInfo",

      },
      {
        title: "Users",
        htmlBefore: '<i class="fas fa-users text-dark"></i>',
        to: "/usersInfo",
      },
      {
        title: "Jury Reports",
        htmlBefore: '<i class="fas fa-users text-dark"></i>',
        to: "/juryReport",
      },
      {
        title: "Jury",
        htmlBefore: '<i class="fas fa-users text-dark"></i>',
        to: "/juryrecords",
      },
    ]
  }
}
